import { createContext, useEffect, useState } from "react";
import { UserProfile } from "../models/User";
import { useNavigate } from "react-router-dom";
// import { loginAPI, registerAPI } from "../Services/AuthService";
import AuthenticationService from "../services/authService";
import { toast } from "react-toastify";
import React from "react";
import axios from "axios";
import { jwtDecode } from 'jwt-decode';

import axiosInstance from "../services/axiosConfig"; // Importa a instância configurada do Axios
import { allowedRoles } from "../configs/apiConfigs";

type UserContextType = {
  user: UserProfile | null;
  token: string | null;
  // registerUser: (email: string, username: string, password: string) => void;
  loginUser: (name: string, password: string) => void;
  logout: () => void;
  isLoggedIn: () => boolean;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserProfile | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("access_token");
    if (user && token) {
      setUser(JSON.parse(user));
      setToken(token);
      axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token; // Configura o cabeçalho de autorização
      // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    setIsReady(true);
  }, []);


  interface decodedTokenType  {
    name: string, 
    sub: string,
    role: string,
    cd_entidade?: number,
  };
  const loginUser = async (email: string, password: string) => {
    await AuthenticationService.login({ email, password })
      .then((res) => {
        if (res) {
          localStorage.setItem("access_token", res?.data.access_token);

          const decodedToken:decodedTokenType = jwtDecode(res.data.access_token);
          // const user:any = res.data.user;

          console.log(res.data.user)

          const userObj = {
            name: res.data.user?.name,
            email: res.data.user?.email,
            role: res.data.user?.role,
            cd_entidade: res.data.user?.cd_entidade,
            diretoria: res.data.user?.diretoria,
            diretoria_descricao: res.data.user?.diretoria_descricao,
          };
          localStorage.setItem("user", JSON.stringify(userObj));
          setToken(res?.data.access_token!);
          setUser(userObj!);
          
          // alert(user)

          localStorage.setItem('isAuthenticated', 'true');
          
          window.location.reload()

          toast.success("Login Success!");

          if (allowedRoles.includes(user?.role ?? '')) {
              // itens = await NotasEmitidasService.getAllItens();
              navigate("/admin");
              
            } else {
              navigate("/admin/conta_movimento_notas");
          }
          

        }
      })
      .catch((e) => {
        console.error(e); // Para mais detalhes sobre o erro
        toast.warning("Server error occured");
      });


      navigate("/admin");

  };

  const isLoggedIn = () => {
    return !!user;
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("isAuthenticated");

    setUser(null);
    setToken("");
    navigate("/");

    window.location.reload()

  };

  return (
    <UserContext.Provider
      // value={{ loginUser, user, token, logout, isLoggedIn, registerUser }}
      value={{ loginUser, user, token, logout, isLoggedIn }}
    >
      {isReady ? children : null}
    </UserContext.Provider>
  );
};

export const useAuth = () => React.useContext(UserContext);