import { Box, Button, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AutoAwesomeMotion from "@mui/icons-material/AutoAwesomeMotion";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import dashBoardService from "../../services/dashBoardService";
import CooperadosService from "../../services/cooperadosService";
import NotasEmitidasService from "../../services/notasEmitidasService";
import React, { useEffect, useState } from "react";
import { NotasAgrupadasType } from "../../models/NotasAgrupadas";
import { NotasEmitidasAgrupadasType, NotasEmitidasType } from "../../models/NotasEmitidas";
import cooperadosService from "../../services/cooperadosService";
import { formatCurrency, formatDate } from "../../configs/helpers";
import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from "date-fns/locale";
import NotasFaturadas from "./notasFaturadas";
import { useAuth } from '../../context/useAuth';
import { allowedRoles } from "../../configs/apiConfigs";
import { useNavigate } from 'react-router-dom';


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [movCooperado, setMovCooperado] = useState<any[]>();
  const [chartData, setChartData] = useState<any[]>([]);
  const [totalVlCusto, setTotalVlCusto] = useState(Number);
  const [qtdeCooperados, setQtdeCooperados] = useState(Number);
  const [notaEmitidasAgrupadas, setNotasEmitidasAgrupadas] = useState<NotasEmitidasAgrupadasType>();
  const [ultimasNotasCooperados, setUltimasNotasCooperados] = useState<NotasEmitidasType[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();



  useEffect(() => {
    // Checa se o papel do usuário está permitido
    if (allowedRoles.includes(user?.role ?? "")) {
      // do nothing, mantem na pagina    
    } else {
      navigate("/admin/conta_movimento_notas"); // Redireciona caso não autorizado
    }
  }, [user, navigate]);




  const convertAnoMesToDate = (anoMes: string | number): Date => {
    if (typeof anoMes !== 'string') {
      anoMes = anoMes.toString();
    }

    // Verificando e convertendo o formato YYYYMM para YYYY-MM
    const year = parseInt(anoMes.substring(0, 4), 10);
    const month = parseInt(anoMes.substring(4, 6), 10) - 1; // Mês começa em 0 no objeto Date

    const date = new Date(year, month);
    // console.log("anoMes:", anoMes); // Adicionando log para verificar a entrada
    // console.log("Converted Date:", date); // Adicionando log para verificar a saída

    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date format: ${anoMes}`);
    }

    return date;
  };




  const filterDataByDateRange = (data: any[], startDate: Date, endDate: Date): any[] => {
    return data.filter(item => {

      console.log(item.ano_mes);
      const itemDate = convertAnoMesToDate(item.ano_mes);

      return itemDate >= startDate && itemDate <= endDate;
    });
  };





  const buscarMovimentacaoGrupo = async (start?: string, end?: string) => {
    const dash = new dashBoardService();
    let mov = await dash.getMovimentacaoPorGrupo(user!);

    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);


      mov = filterDataByDateRange(mov, startDate, endDate);
      console.log("Após filtro");
      console.log(mov);
    }

    setMovCooperado(mov);
    prepareChartData(mov);

    const totalVlCusto_ = mov.reduce((acc, item) => {
      return acc + (typeof item.vl_custo === 'number' ? item.vl_custo : Number(item.vl_custo));
    }, 0);

    setTotalVlCusto(totalVlCusto_);
  };





  const fetchQtdeCooperados = async () => {
    const qtdeCooperados = await cooperadosService.countItens()
    setQtdeCooperados(Number(qtdeCooperados));
  }

  const filterNotasByDateRange = (notas: NotasEmitidasType[], startDate: Date, endDate: Date): NotasEmitidasType[] => {
    return notas.filter(nota => {
      const notaDate = new Date(nota.dt_emissao || '');
      return notaDate >= startDate && notaDate <= endDate;
    });
  };


  const fechtNotasEmitidas = async (start?: Date, end?: Date) => {
    const notas = await NotasEmitidasService.getAllItens();

    let filteredNotas = notas;

    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      filteredNotas = filterNotasByDateRange(notas, startDate, endDate);
    }

    setUltimasNotasCooperados(filteredNotas.slice(0, 10));

    const notaEmitidasAgrupadasVar: NotasEmitidasAgrupadasType = {
      vl_total: filteredNotas.reduce((total, registro) => total + (registro.vl_total || 0), 0),
      qtde_notas: filteredNotas.length,
    };
    setNotasEmitidasAgrupadas(notaEmitidasAgrupadasVar);
  };


  const prepareChartData = (data: NotasAgrupadasType[]) => {
    const groupedData = data.reduce((acc, item) => {
      const foundGroup = acc.find(group => group.id === item.subgrupo);
      if (foundGroup) {
        foundGroup.data.push({ x: Number(item.ano_mes), y: Number(item.vl_custo) });
      } else {
        acc.push({
          id: item.subgrupo || "",
          data: [{ x: Number(item.ano_mes), y: Number(item.vl_custo) }]
        });
      }
      return acc;
    }, [] as { id: string, data: { x: number, y: number }[] }[]);
    setChartData(groupedData);
  };


  useEffect(() => {
    buscarMovimentacaoGrupo(); // Chama a função ao montar o componente
    fetchQtdeCooperados();
    fechtNotasEmitidas();
  }, []); // O array vazio garante que a função seja chamada apenas uma vez ao montar



  const handleFilter = () => {
    if (startDate && endDate) {
      const start = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}`;
      const end = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}`;

      console.log("Datas ");
      console.log(start);
      console.log(end);

      buscarMovimentacaoGrupo(start, end);
      fechtNotasEmitidas(startDate, endDate);

    }
  };

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    buscarMovimentacaoGrupo();
    fechtNotasEmitidas();

  };



  return (
    <Box m="20px">
      {/* HEADER */}
      {/* <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" alignItems={isMobile ? "flex-start" : "center"}>
        <Header title="Coopoli" subtitle="Sistema de gestão de informações" />
      </Box> */}

      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* LOGO */}
          <Box display="flex" alignItems="center">
            <img src="/assets/logotipo.png" alt="Logo Coopoli" width="100" height="100" />
            <Box ml={2}>
              <Typography variant="h1" color="primary" >
                Coopoli
              </Typography>
              <Typography variant="h5" color="textSecondary">
                Sistema de gestão de informações
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* <button onClick={buscarMovimentacaoGrupo}>Teste</button> */}
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent="space-between" alignItems={isMobile ? "flex-start" : "center"} mb="20px">
        <Header title="Resultados" subtitle="Bem vindo ao seu dashboard!" />
        <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap="10px" width={isMobile ? "100%" : "auto"}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <DatePicker
              label="Data Inicial"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: isMobile ? "100%" : "auto",
                    mb: isMobile ? "10px" : "0",
                  }}
                />
              )}
            />
            <DatePicker
              label="Data Final"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: isMobile ? "100%" : "auto",
                    mb: isMobile ? "10px" : "0",
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap="10px" width={isMobile ? "100%" : "auto"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFilter}
              sx={{
                width: isMobile ? "100%" : "auto",
                padding: "10px",
              }}
            >
              Filtrar
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={clearFilter}
              sx={{
                width: isMobile ? "100%" : "auto",
                padding: "10px",
              }}
            >
              Limpar Filtros
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        gridColumn={isMobile ? "span 12" : "span 4"}

        display="flex"
        justifyContent="left"
        alignItems="left"
        mb="20px"
      >
        <Box
          gridColumn={isMobile ? "span 12" : "span 4"}
          sx={{ backgroundColor: colors.primary[400] }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${qtdeCooperados}`}
            subtitle="Cooperados"
            progress={0.9}
            increase=""
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

      </Box>
      <Box>
        <NotasFaturadas dataInicial={startDate} dataFinal={endDate} />

      </Box>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        {/* <Box
          gridColumn={isMobile ? "span 12" : "span 4"}
          sx={{ backgroundColor: colors.primary[400] }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${notaEmitidasAgrupadas?.qtde_notas}`}
            subtitle="Total Aquisição Cooperado"
            progress={0.3}
            increase=""
            icon={
              <AutoAwesomeMotion
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={isMobile ? "span 12" : "span 4"}
          sx={{ backgroundColor: colors.primary[400] }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${formatCurrency(notaEmitidasAgrupadas?.vl_total || 0)}`}
            subtitle="(R$) Notas Aquisição Cooperado"
            progress={0.60}
            increase=""
            icon={
              <AttachMoney
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}


        {/* ROW 2 */}
        <Box
          gridColumn={isMobile ? "span 12" : "span 12"}
          gridRow="span 2"
          sx={{ backgroundColor: colors.primary[400] }}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Aquisições de Cooperados por Grupo
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {/* $59,342.32 */}

                {Number(totalVlCusto).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Typography>
            </Box>
            {/* <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[200] }}
                />
              </IconButton>
            </Box> */}
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            {/* <LineChart isDashboard={true} /> */}
            <LineChart isDashboard={true} data={chartData} />

          </Box>
        </Box>
        {/* <Box
          gridColumn={isMobile ? "span 12" : "span 4"}
          gridRow="span 2"
          sx={{ backgroundColor: colors.primary[400] }}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: colors.primary[400],
              colors: colors.grey[100]
            }}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Últimas 10 notas de Cooperados
            </Typography>
          </Box>
          {ultimasNotasCooperados.map((nota, i) => (
            <Box
              key={`${nota.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[600]}
                  variant="h5"
                  fontWeight="600"
                >
                  NF {nota.nr_documento} <br />

                </Typography>
                <Typography color={colors.grey[100]}>
                  {nota.name}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{formatDate(nota.dt_lancamento || 0)}</Box>
              <Box
                sx={{ backgroundColor: colors.primary[400], color: colors.greenAccent[600] }}
                p="5px 10px"
                borderRadius="4px"
              >
                {formatCurrency(nota.vl_total || 0)}
              </Box>
            </Box>
          ))}
        </Box> */}

        {/* ROW 3 */}
        {/* <Box
          gridColumn={isMobile ? "span 12" : "span 4"}
          gridRow="span 2"
          sx={{ backgroundColor: colors.primary[400] }}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[200]}
              sx={{ mt: "15px" }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box
          gridColumn={isMobile ? "span 12" : "span 4"}
          gridRow="span 2"
          sx={{ backgroundColor: colors.primary[400] }}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn={isMobile ? "span 12" : "span 4"}
          gridRow="span 2"
          sx={{ backgroundColor: colors.primary[400] }}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
